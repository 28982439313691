var commonjsGlobal = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function getDefaultExportFromCjs(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var roundHalfEven_min = { exports: {} };
(function(e, a) {
  (function(t, i) {
    e.exports = i();
  })(typeof self < "u" ? self : commonjsGlobal, function() {
    return function(t) {
      function i(n) {
        if (o[n])
          return o[n].exports;
        var l = o[n] = { i: n, l: !1, exports: {} };
        return t[n].call(l.exports, l, l.exports, i), l.l = !0, l.exports;
      }
      var o = {};
      return i.m = t, i.c = o, i.d = function(n, l, r) {
        i.o(n, l) || Object.defineProperty(n, l, { configurable: !1, enumerable: !0, get: r });
      }, i.n = function(n) {
        var l = n && n.__esModule ? function() {
          return n.default;
        } : function() {
          return n;
        };
        return i.d(l, "a", l), l;
      }, i.o = function(n, l) {
        return Object.prototype.hasOwnProperty.call(n, l);
      }, i.p = "", i(i.s = 0);
    }([function(t, i, o) {
      var n = o(1), l = function(r) {
        return r && r.__esModule ? r : { default: r };
      }(n);
      t.exports = l.default;
    }, function(t, i, o) {
      var n = function(r) {
        return r % 2 == 0;
      }, l = function r(u) {
        var s = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 2;
        if (u === void 0)
          throw new Error("value is required");
        if (typeof u != "number")
          throw new Error("value must be a number type");
        if (u < 0)
          return -r(-u, s);
        if (s === 0)
          return 10 * r(u / 10, 1);
        var c = ("" + u).replace(/0+$/, ""), d = c.indexOf(".");
        if (d < 0)
          return u;
        var p = c.slice(0, d);
        p.length == 0 && (p = 0);
        var f = c.slice(d + 1);
        if (f.length < s)
          return u;
        var P = parseInt(f[s], 10);
        if (P < 5) {
          var m = f.slice(0, s);
          return parseFloat(p + "." + m);
        }
        if (P === 5) {
          var O = f.slice(0, s + 1);
          f = parseInt(f.slice(s + 1), 10) > 0 ? O + "9" : O;
        }
        for (var y = parseInt(f[f.length - 1], 10), g = 0, C = f.length - 1; C >= s; C--) {
          var v = parseInt(f[C - 1], 10) + g;
          (y > 5 || y == 5 && !n(v)) && ++v, v > 9 ? (v -= 10, g = 1) : g = 0, y = v;
        }
        for (var S = "", D = s - 2; D >= 0; D--) {
          var V = parseInt(f[D], 10) + g;
          V > 9 ? (V -= 10, g = 1) : g = 0, S = "" + V + S;
        }
        return p = parseInt(p, 10) + g, parseFloat(p + "." + S + y);
      };
      t.exports = l;
    }]);
  });
})(roundHalfEven_min);
const roundHalfEven = /* @__PURE__ */ getDefaultExportFromCjs(roundHalfEven_min.exports), apiService = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE")
};
function request(e) {
  let a = null;
  return async (t, i, o, n, l) => {
    const r = {
      method: e,
      headers: {},
      credentials: "include"
    };
    if (r.headers["Content-Type"] = "application/json", o != null && o.forEach((u) => {
      r.headers[u.key] = u.value;
    }), a = new URL(t), n != null && n.length > 0) {
      let u = n.map((s) => `${s.key}=${s.value}`).join("&");
      a.search = u;
    }
    return i && (r.body = JSON.stringify(i)), await enqueueAPICall(a, r, l);
  };
}
const apiQueue = [];
async function enqueueAPICall(e, a, t) {
  if (t) {
    const i = new Promise((o, n) => {
      apiQueue.push({ url: e, options: a, resolve: o, reject: n });
    });
    return apiQueue.length === 1 && await processNext(), i;
  } else
    return fetch(e, a).then((i) => i.json()).then((i) => i).catch((i) => {
      throw console.error("API call error:", i), i;
    });
}
async function processNext() {
  if (apiQueue.length === 0)
    return;
  const { url: e, options: a, resolve: t, reject: i } = apiQueue[0];
  try {
    const n = await (await fetch(e, a)).json();
    apiQueue.shift(), t(n), await processNext();
  } catch (o) {
    console.error("API call error:", o), apiQueue.shift(), i(o), await processNext();
  }
}
let inputPricingObject = null;
const roundType = {
  NO_ROUNDING: "NO_ROUNDING",
  ROUND_UP: "ROUND_UP",
  ROUND_DOWN: "ROUND_DOWN",
  ROUND_EVEN_HALF: "ROUND_EVEN_HALF"
}, policyFee = "Fee";
let endPoints = {
  CreatePolicy: {},
  GetPlanPriceInst: {},
  PricingPolicyMapping: {},
  EndorsementPlanPrice: {},
  // RewardPointVouchers: {},
  RetrievePolicy: {},
  GetPlanPrice: {},
  GetCalculationConfiguration: {},
  MultiplePlanPrice: {},
  GetDependencyCodeMapping: {},
  EndorsementInst: {}
}, paymentFrequency = null, dependencyMapping = null, codePremAndInstallmentSteps = {}, depCodeOptionsList = {}, selectedPlan = {}, paymentFrequencyArr = [], quote = null, coveragePrice = {}, coverageOptionPrice = {}, productConfig = null, initConfig = null, policyPaymentOptionPrice = {}, clientRegData = null, planPriceWithInstResponse = [], policyServiceInputObj = null, quoteId = null, headerUUID = [], policyProductVersionNo = null, productType = null, policyServiceMappingFromPricing = null, paymentOptions = [], paymentDetails = [], latestTransactedQuoteId = null, latestPolicyResponse = null;
const isValid = (e) => e != null && e !== "";
async function setClientRegInfo(e) {
  clientRegData = e.response, createHeaderUUID(clientRegData.UUID), await initPricingEngine(e.productType, clientRegData.initServiceUrl);
}
function setLatestPolicyResponse(e) {
  latestPolicyResponse = e;
}
function getPlanPriceWithInstResponse() {
  return planPriceWithInstResponse;
}
async function initPricingEngine(e, a) {
  let t = {};
  t.ProductType = e, t.LibName = "IGNPricingEngine", validateEndpoints(await apiService.post(a, t, headerUUID, null));
}
async function validateEndpoints(e) {
  let a = [];
  if (Object.keys(endPoints).forEach((t) => {
    Object.keys(e).includes(t) ? endPoints[t] = e[t] : a.push(t);
  }), a.length != 0)
    throw "Missing End Points: " + a;
}
function setPolicyServiceInputObj(e) {
  policyServiceInputObj = e;
}
function getPolicyServiceInputObj() {
  return policyServiceInputObj;
}
function getPaymentDetailsData() {
  return paymentDetails;
}
async function calculatePlanOptionsPrice(e = !0, a) {
  if (e) {
    await initProductConfig(inputPricingObject.root.Quote.Product_Type), isValid(policyServiceMappingFromPricing) || await initPricingPolicyConfig();
    let t = {};
    if (JSON.stringify(selectedPlan) !== "{}" && (isValid(dependencyMapping) || await initDependencyMapping(), await updateSelectedPlanForInputPricing()), validateToCallInterceptorURL(
      endPoints.MultiplePlanPrice.interceptorUrl,
      a
    )) {
      let i = makeInterceptorRequestObj(
        inputPricingObject,
        endPoints.MultiplePlanPrice.interceptorServiceUrl
      );
      t = await apiService.post(
        endPoints.MultiplePlanPrice.interceptorUrl,
        i,
        a.metadata.headers,
        a.metadata.requestParams,
        endPoints.MultiplePlanPrice.flagConcurrent
      );
    } else
      t = await apiService.post(
        endPoints.MultiplePlanPrice.url,
        inputPricingObject,
        headerUUID,
        null,
        endPoints.MultiplePlanPrice.flagConcurrent
      );
    quote = t.root[0].Quote[0], updateMasterSetIdAndProductVersion(quote.mastersetId, quote.Policy_Product_Version), isValid(dependencyMapping) || await initDependencyMapping(), depCodeOptionsList = {};
  }
  calculateDefaultSelectedOptionPrice(), prepareAvailableOptions();
}
async function calculatePlanPriceWithPaymentOptions(e, a, t, i, o) {
  let n = null;
  if (validateToCallInterceptorURL(t, e)) {
    let l = makeInterceptorRequestObj(inputPricingObject, o);
    n = await apiService.post(
      t,
      l,
      e.metadata.headers,
      e.metadata.requestParams,
      i
    );
  } else
    n = await apiService.post(
      a,
      inputPricingObject,
      headerUUID,
      null,
      i
    );
  createPlanPricePaymentFrequency(n);
}
function createPlanPricePaymentFrequency(e) {
  let a = e.root.Quote.Loading_Factors;
  isValid(quote) || (quote = e.root.Quote), paymentFrequencyArr = [], isValid(a) && (paymentOptions = [], a.forEach((t) => {
    let i = {};
    i.nextPendingInstallmentDate = t.Next_Pending_Installment_Date, i.firstInstallmentAmount = t.First_Installment_Amount, i.totalPremium = t.Total_Premium, i.recurringAmount = t.Recurring_Amount, i.firstPaymentDueDate = t.First_Payment_Due_Date, policyPaymentOptionPrice[t.Payment_Frequency] = i, paymentFrequencyArr.push(t.Payment_Frequency), createPolicyPaymentOptionsObj(t);
  })), isValid(paymentFrequency) || (paymentFrequency = e.root.Quote.Default_Show_Payment_Frequency), planPriceWithInstResponse = e;
}
function createPolicyPaymentOptionsObj(e) {
  let a = {}, t = {};
  t.installAmount = e.First_Installment_Amount, t.fullPremuim = e.Total_Premium, a.paymentFreqeuncy = e.Payment_Frequency, a.paymentOptionAmount = t, paymentOptions.push(a);
}
function getPolicyPaymentOptionPrice() {
  return policyPaymentOptionPrice;
}
function getRewardPoint() {
  var e;
  return isValid(quote.Reward_Point) ? quote.Reward_Point : (e = coveragePrice.Policy) == null ? void 0 : e.rewardPoint;
}
async function initProductConfig(e, a) {
  let t = { productType: e };
  initConfig = await apiService.post(
    endPoints.GetCalculationConfiguration.url,
    t,
    headerUUID,
    null,
    endPoints.CreatePolicy.flagConcurrent
  );
}
async function initPricingPolicyConfig() {
  let a = { productType: isValid(inputPricingObject) ? inputPricingObject.root.Quote.Product_Type : productType };
  policyServiceMappingFromPricing = await apiService.post(
    endPoints.PricingPolicyMapping.url,
    a,
    headerUUID,
    null,
    endPoints.PricingPolicyMapping.flagConcurrent
  );
}
async function getDependencyCodeMapping() {
  let e = {
    productVersionNo: policyProductVersionNo,
    productType
  };
  return await apiService.post(
    endPoints.GetDependencyCodeMapping.url,
    e,
    headerUUID,
    null,
    endPoints.CreatePolicy.flagConcurrent
  );
}
async function initDependencyMapping() {
  dependencyMapping = {};
  let e = [];
  if (isValid(quote))
    e = quote.AvailableOptions;
  else {
    let a = await getDependencyCodeMapping();
    isValid(a) && (e = a.AvailableOptions);
  }
  for (let a = 0; a < e.length; a++) {
    let t = e[a], i = getCoverageFromDependencyMapping(
      t.Code,
      dependencyMapping
    );
    if (i.selectOptions[t.Option_Seq] = {
      optionName: t.Option_Name,
      flagDep: t.Option_Name.indexOf(".") > 0
    }, isValid(selectedPlan[t.Code]) || (selectedPlan[t.Code] = { defaultPlan: null }), selectedPlan[t.Code][t.Option_Name] = null, t.Option_Name.indexOf(".", 0) < 0 && (i.flagOtherCodeDepOptions = !1), !i.flagOtherCodeDepOptions && t.Option_Seq > 1 && (i.flagMultipleOptionExists = !0), t.Flag_Additional_Coverage) {
      let o = getCoverageFromDependencyMapping(
        t.Parent_Cover,
        dependencyMapping
      );
      i.flagCover = !1, i.parentCover = t.Parent_Cover, o.flagAdditionalCoverageExist = !0, t.Option_Seq == 1 && o.additionalCoverages.push(t.Code);
    } else
      codePremAndInstallmentSteps[t.Code] = {};
    isValid(t.Premium_Dependent_Code) && (i.flagPremiumDepedent = !0, i.selectOptions[t.Option_Seq].premiumDepedentCode = t.Premium_Dependent_Code.split(","), i.premiumDepedentCode = t.Premium_Dependent_Code.split(",")), isValid(t.Selection_Dependent_Code) && (i.flagSelectionDepedent = !0);
  }
  initConfig.productConfiguration.flagCalcPolicyFee && (dependencyMapping[policyFee] = {}, codePremAndInstallmentSteps[policyFee] = {});
}
function initPremCalculationStepsOnCover(e, a) {
  let i = initConfig.premCalculationStepList.filter(
    (o) => o.coverCode === e
  );
  if (isValid(i) && i.length > 0)
    i.sort(function(o, n) {
      return o.calcStepNo - n.calcStepNo;
    });
  else
    throw "Cover code not found.";
  a.premCalcSteps = i;
}
function initPaymentCalculationStepsOnCover(e, a, t) {
  let o = initConfig.paymentCalculationStepList.filter(
    (n) => n.coverCode === e && n.paymentFrequency === t
  );
  a[t] = {}, a[t].paymentCalcSteps = o;
}
function getCoverageFromDependencyMapping(e, a) {
  let t = a[e];
  return isValid(t) || (t = {
    flagCover: !0,
    flagAdditionalCoverageExist: !1,
    additionalCoverages: [],
    selectOptions: {},
    flagPremiumDepedent: !1,
    flagSelectionDepedent: !1,
    premiumDepedentCode: [],
    flagMultipleOptionExists: !1,
    flagOtherCodeDepOptions: !0
  }, a[e] = t), t;
}
function updateSelectPlanCoverageOption(e, a) {
  let t = selectedPlan[e];
  if (!!isValid(t))
    if (dependencyMapping[e].flagMultipleOptionExists)
      if (isValid(t.defaultPlan)) {
        let i = t.defaultPlan.split("_");
        for (const o in i) {
          const n = dependencyMapping[e].selectOptions[parseInt(o) + 1].optionName, l = i[o];
          t[n] = l, isValid(coverageOptionPrice[e][n]) || (coverageOptionPrice[e][n] = {}), coverageOptionPrice[e][n][l] = a;
        }
      } else
        Object.keys(dependencyMapping[e].selectOptions).forEach((i) => {
          const o = dependencyMapping[e].selectOptions[i].optionName;
          coverageOptionPrice[e][o] = a, t[o] = a;
        });
    else {
      const i = dependencyMapping[e].selectOptions[1].optionName;
      coverageOptionPrice[e][i] = a, dependencyMapping[e].flagOtherCodeDepOptions || (t[i] = t.defaultPlan);
    }
}
function updateDefaultPlanOnDepOption(e) {
  let a = null, t = [];
  dependencyMapping[e].flagSelectionDepedent && !dependencyMapping[e].flagOtherCodeDepOptions && isValid(selectedPlan[e].defaultPlan) && (t = selectedPlan[e].defaultPlan.split("_")), Object.keys(dependencyMapping[e].selectOptions).forEach(
    (i) => {
      const o = dependencyMapping[e].selectOptions[i].optionName;
      if (o.indexOf(".") > 0) {
        const n = o.split("."), l = selectedPlan[n[0]][n[1]];
        selectedPlan[e][o] = l, a = isValid(a) ? a + "_" + l : l;
      } else {
        const n = t[i - 1];
        a = isValid(a) ? a + "_" + n : n;
      }
    }
  ), selectedPlan[e].defaultPlan = a;
}
function getCoverageDefaultSelectedPlan(e, a) {
  isValid(selectedPlan[e].defaultPlan) && (dependencyMapping[e].flagOtherCodeDepOptions || dependencyMapping[e].flagSelectionDepedent) && updateDefaultPlanOnDepOption(e);
  let t = selectedPlan[e].defaultPlan, i = null;
  return isValid(t) && (i = a.Option.filter(
    (o) => o.Name === t
  )), (!isValid(t) || !isValid(i) || i.length == 0) && (t = a.Default_Selected_Option, selectedPlan[e].defaultPlan = isValid(t) ? t : null, i = a.Option.filter(
    (o) => o.Name === t
  )), i;
}
function calculateGrossPremium(totalNetPremium, coverCode) {
  try {
    isValid(codePremAndInstallmentSteps[coverCode].premCalcSteps) || initPremCalculationStepsOnCover(
      coverCode,
      codePremAndInstallmentSteps[coverCode]
    );
    let calculationSteps = codePremAndInstallmentSteps[coverCode].premCalcSteps;
    if (isValid(calculationSteps)) {
      let Cover_Gross_Premium = 0, Transaction_Net_Prem = 0, calcStepResults = {}, calcStepObj = {}, calcStepCode = {}, calcStepCoverCode = {};
      for (let calcIndex = 0; calcIndex < calculationSteps.length; calcIndex++) {
        let step = calculationSteps[calcIndex], isConditionMetForCalc = !1;
        if (isValid(step.condition) && (isConditionMetForCalc = validateStepExecRule(
          step.condition,
          calcStepObj
        )), step.code == "Transaction_Net_Prem") {
          Transaction_Net_Prem = roundValue(
            roundType[step.roundingType],
            totalNetPremium,
            step.roundingNum
          ), calcStepResults[step.code] = Transaction_Net_Prem, calcStepCode[step.code] = Transaction_Net_Prem, calcStepCoverCode[coverCode] = calcStepCode, calcStepObj.calcStep = calcStepCoverCode;
          continue;
        } else if (step.code == "Cover_Gross_Premium") {
          Cover_Gross_Premium = roundValue(
            roundType[step.roundingType],
            totalNetPremium,
            step.roundingNum
          ), calcStepResults[step.code] = Cover_Gross_Premium, calcStepCode[step.code] = Cover_Gross_Premium, calcStepCoverCode[coverCode] = calcStepCode, calcStepObj.calcStep = calcStepCoverCode;
          continue;
        } else {
          if (!isValid(step.condition) || isConditionMetForCalc) {
            let stepFormula = calcStepFormula(step.formula), replaceFormula = step.formula;
            for (let e = 0; e < stepFormula.length; e++)
              isValid(stepFormula[e]) && isNumeric(stepFormula[e]) || isValid(stepFormula[e]) && !isNumeric(stepFormula[e]) && (replaceFormula = replaceFormula.replace(
                stepFormula[e],
                calcStepResults[stepFormula[e].trim()]
              ));
            calcStepResults[step.code] = roundValue(
              roundType[step.roundingType],
              eval(replaceFormula),
              step.roundingNum
            );
          } else
            calcStepResults[step.code] = 0;
          calcStepCode[step.code] = calcStepResults[step.code], calcStepCoverCode[coverCode] = calcStepCode, calcStepObj.calcStep = calcStepCoverCode;
        }
      }
      return calcStepResults.Gross_Premium;
    }
  } catch (e) {
    console.log(e);
  }
}
function validateStepExecRule(condition, calcStepObj) {
  let pricingRoot = inputPricingObject.root, pricingObj = { ...pricingRoot };
  const quotePattern = /\bQuote\.[\w.]+/g, calcStepPattern = /\bcalcStep\.[\w.]+/g;
  return condition = condition.replace(quotePattern, function(e) {
    return getJsonPathValue(pricingObj, e);
  }), condition = condition.replace(calcStepPattern, function(e) {
    return getJsonPathValue(calcStepObj, e);
  }), eval(condition);
}
function getJsonPathValue(e, a) {
  const t = a.split(".");
  let i;
  return t.forEach((o) => {
    e[o].constructor == Object ? e = e[o] : e[o].constructor == Array || e[o].constructor == String && (i = String(e[o]));
  }), i;
}
function calcStepFormula(e) {
  return e.split(/[-+*\/()]/);
}
function isNumeric(e) {
  return !isNaN(parseFloat(e)) && isFinite(e);
}
function calculateInstallmentAmount(e, a) {
  let t = {};
  try {
    let i = 0, o = 0;
    isValid(codePremAndInstallmentSteps[a][paymentFrequency]) || initPaymentCalculationStepsOnCover(
      a,
      codePremAndInstallmentSteps[a],
      paymentFrequency
    );
    let n = codePremAndInstallmentSteps[a][paymentFrequency].paymentCalcSteps;
    if (isValid(n))
      n[0].installmentPercentage != 1 ? (i = roundValue(
        roundType[n[0].roundingType],
        n[0].installmentPercentage * e,
        n[0].roundingNum
      ), o = roundValue(
        roundType[n[0].roundingType],
        i * n[0].downPaymentInstCount,
        n[0].roundingNum
      )) : (o = e, i = null), t = { firstInstallmentAmount: o }, t.recurringAmount = i;
    else
      throw "Cover code not found.";
  } catch (i) {
    console.log(i);
  }
  return t;
}
function roundValue(e, a, t) {
  if (e === roundType.ROUND_EVEN_HALF)
    return roundHalfEven(a, t);
  if (e === roundType.ROUND_UP) {
    let i = Math.pow(10, t);
    return parseFloat(Math.ceil(a * i) / i);
  } else if (e === roundType.ROUND_DOWN) {
    let i = Math.pow(10, t);
    return parseFloat(Math.floor(a * i) / i);
  } else
    return parseFloat(a);
}
function preparePaymentLoadingData() {
  paymentFrequency = isValid(
    inputPricingObject.root.Quote.Policy_Payment_Frequency
  ) ? inputPricingObject.root.Quote.Policy_Payment_Frequency : quote.Default_Show_Payment_Frequency;
  let e = null;
  return paymentFrequencyArr = [], quote.Loading_Factors.forEach((a) => {
    a.Payment_Frequency == paymentFrequency && (e = a), paymentFrequencyArr.push(a.Payment_Frequency);
  }), parseFloat(e.Payment_Loading);
}
function getDefaultPaymentFrequency() {
  return paymentFrequency;
}
function getAvailablePaymentFrequency() {
  return paymentFrequencyArr;
}
function updatePaymentFrequency(e, a) {
  inputPricingObject.root.Quote.Policy_Payment_Frequency = e, paymentFrequency = e, a === !0 && (calculateDefaultSelectedOptionPrice(), prepareAvailableOptions());
}
function calculateDefaultSelectedOptionPrice() {
  let e = 0, a = 0, t = 0;
  productConfig = initConfig.productConfiguration;
  let i = 0, o = 0, n = 0, l = 0, r = 0, u = preparePaymentLoadingData();
  for (let c of Object.keys(selectedPlan)) {
    e = 0, a = 0;
    let d = dependencyMapping[c];
    if (!d.flagCover)
      continue;
    let p = quote.Coverages[c], f = p.Flag_Apply_Loading_Factor, P = getCoverageDefaultSelectedPlan(
      c,
      p
    );
    if (isValid(P) && P.length > 0 && (a = parseFloat(P[0].Premium_Before_Taxes), e = a, t = t + parseFloat(P[0].Reward_Point)), coverageOptionPrice[c] = { gwtPremium: a }, updateSelectPlanCoverageOption(c, a), d.flagAdditionalCoverageExist && d.additionalCoverages.forEach((g) => {
      a = 0, p = quote.Coverages[g], P = getCoverageDefaultSelectedPlan(
        g,
        p
      ), isValid(P) && P.length > 0 && (a = parseFloat(
        P[0].Premium_Before_Taxes
      ), e = e + a, t = t + parseFloat(P[0].Reward_Point)), coverageOptionPrice[g] = { gwtPremium: a }, updateSelectPlanCoverageOption(g, a);
    }), d.flagPremiumDepedent) {
      a = coverageOptionPrice[c].gwtPremium;
      for (const g in d.premiumDepedentCode) {
        const C = d.premiumDepedentCode[g];
        a = a + coverageOptionPrice[C].gwtPremium;
      }
      coverageOptionPrice[c].gwtPremium = a;
    }
    coverageOptionPrice[c].gwtPremiumWithLoading = 0;
    let m = e;
    f && u != 1 && (m = roundValue(
      roundType[productConfig.loadingFactorRoundingType],
      e * u,
      productConfig.loadingFactorRoundingNum
    )), coveragePrice[c] = { gwtPremium: m };
    let O = calculateGrossPremium(
      m,
      c
    );
    coveragePrice[c].coverGrossPremium = O, i = i + O, o = o + i;
    let y = calculateInstallmentAmount(
      O,
      c
    );
    coveragePrice[c].firstInstallmentAmount = y.firstInstallmentAmount, coveragePrice[c].recurringAmount = y.recurringAmount, n = n + y.firstInstallmentAmount, l = l + y.recurringAmount;
  }
  if (productConfig.flagCalcPolicyFee) {
    coveragePrice[policyFee] = { gwtPremium: i };
    let c = calculateGrossPremium(
      roundValue(
        roundType[productConfig.loadingFactorRoundingType],
        i,
        productConfig.loadingFactorRoundingNum
      ),
      policyFee
    );
    o = o + c, coveragePrice[policyFee].coverGrossPremium = c;
    let d = calculateInstallmentAmount(
      c,
      policyFee
    );
    coveragePrice[policyFee].firstInstallmentAmount = d.firstInstallmentAmount, coveragePrice[policyFee].recurringAmount = d.recurringAmount, n = n + d.firstInstallmentAmount, l = l + d.recurringAmount;
  }
  let s = {};
  s.gwtPremium = r, s.coverGrossPremium = o, s.firstInstallmentAmount = n, s.recurringAmount = l, s.rewardPoint = t, coveragePrice.Policy = s, isValid(policyServiceInputObj == null ? void 0 : policyServiceInputObj.root.quoteData.transactionType) && (policyServiceInputObj == null ? void 0 : policyServiceInputObj.root.quoteData.transactionType) != "New Biz" && (policyServiceInputObj == null ? void 0 : policyServiceInputObj.root.quoteData.transactionType) != "Renewal" && calculateEndorsementPrice();
}
function setInputPricing(e) {
  inputPricingObject = e;
}
function getInputPricing() {
  return inputPricingObject;
}
function getDependencyMapping() {
  return dependencyMapping;
}
function getSelectedPlan() {
  return selectedPlan;
}
function getCoveragePrice() {
  return coveragePrice;
}
function getCoverageOptionPrice() {
  return coverageOptionPrice;
}
function initPlanOptionObject(e = 0, a = 0, t = "", i = !1) {
  return {
    prem: e,
    diff: a,
    matchVal: 0,
    matchFormat: "",
    defaultOptionName: t,
    defaultPlan: i
  };
}
function compareAndUpdateOptionList(e, a, t, i, o, n, l, r) {
  let u = 0, s = "", c = !0, d = e.Name.split("_");
  if (i) {
    for (const f in l)
      if (d[l[f] - 1] != r[f])
        return;
  }
  let p = null;
  if (n)
    for (const f in d)
      !isValid(a) || a[f] != d[f] ? (s = s + "0", c && (p = t[parseInt(f) + 1][d[f]], isValid(p) || (p = initPlanOptionObject(0, 0), t[parseInt(f) + 1][d[f]] = p), c = !1)) : (u = u + 1, s = s + "1");
  else
    e.Name != a && (p = initPlanOptionObject(
      e.Premium_Before_Taxes,
      0
    ), t[1][e.Name] = p);
  isValid(p) && (!isValid(p.defaultOptionName) || p.matchVal < u || u >= p.matchVal && p.prem >= e.Premium_Before_Taxes) && (p.matchVal = u, p.matchFormat = s, p.prem = e.Premium_Before_Taxes, p.diff = e.Premium_Before_Taxes - o, p.defaultOptionName = e.Name);
}
function prepareAvailableOptionsForAnItem(e) {
  let a = quote.Coverages[e], t = dependencyMapping[e].flagPremiumDepedent, i = coverageOptionPrice[e].gwtPremium, o = [], n = selectedPlan[e].defaultPlan;
  isValid(selectedPlan[e].defaultPlan) || (n = "", i = 0);
  let l = {};
  if (dependencyMapping[e].flagMultipleOptionExists) {
    o = n.split("_");
    for (const c in o) {
      let d = {};
      d[o[parseInt(c)]] = initPlanOptionObject(i, 0, n, !0), l[parseInt(c) + 1] = d;
    }
  } else if (o = n, l[1] = {}, isValid(selectedPlan[e].defaultPlan)) {
    let c = {};
    c[n] = initPlanOptionObject(
      i,
      0,
      n,
      !0
    ), l[1] = c;
  }
  let r = [], u = [];
  dependencyMapping[e].flagSelectionDepedent && Object.keys(dependencyMapping[e].selectOptions).forEach((c) => {
    const d = dependencyMapping[e].selectOptions[c];
    if (d.flagDep) {
      const p = d.optionName.split("."), f = selectedPlan[p[0]][p[1]];
      isValid(f) && (u.push(c), r.push(f));
    }
  }), a.Option.forEach((c) => {
    compareAndUpdateOptionList(
      c,
      o,
      l,
      dependencyMapping[e].flagSelectionDepedent,
      i,
      dependencyMapping[e].flagMultipleOptionExists,
      u,
      r
    );
  });
  let s = !1;
  t && dependencyMapping[e].premiumDepedentCode.forEach(
    (c) => {
      let d = selectedPlan[c].defaultPlan;
      isValid(d) && (s = !0, populateDepCodeOptionsList(
        e,
        c,
        d
      ));
    }
  ), calcPremDifferenceOnDepedentOption(
    e,
    l,
    t && s
  );
}
function populateDepCodeOptionsList(e, a, t) {
  if (depCodeOptionsList[e] = isValid(depCodeOptionsList[e]) ? depCodeOptionsList[e] : {}, isValid(depCodeOptionsList[e][a])) {
    let i = generateSelectionFormatForDepCode(
      e,
      a,
      t
    );
    depCodeOptionsList[e][a].optionSelectFormat = i;
  } else {
    let i = {};
    i = { optionSelectFormat: generateSelectionFormatForDepCode(
      e,
      a,
      t
    ) }, quote.Coverages[a].Option.forEach((n) => {
      i[n.Name] = n.Premium_Before_Taxes;
    }), depCodeOptionsList[e][a] = i;
  }
}
function calcPremDifferenceOnDepedentOption(e, a, t) {
  let i = 1, o = null, n = !1;
  coverageOptionPrice[e].gwtPremiumWithLoading = 0, Object.keys(a).forEach((l) => {
    Object.keys(a[l]).forEach((r) => {
      n = r == "1" ? !0 : n;
      let u = a[l][r], s = 0, c = u.defaultOptionName.split("_");
      if (!isValid(selectedPlan[e].defaultPlan) || c.length - 1 == u.matchVal || u.defaultPlan) {
        t && !u.defaultPlan && dependencyMapping[e].premiumDepedentCode.forEach(
          (P) => {
            let m = selectedPlan[P].defaultPlan;
            isValid(m) && (s += getDepedentCodePremiumOfCover(
              e,
              P,
              u.defaultOptionName
            ));
          }
        );
        let d = parseFloat(
          a[l][r].prem
        );
        d = s + d;
        let p = coverageOptionPrice[e].gwtPremium;
        a[l][r].prem = d, a[l][r].diff = d - p, i = preparePaymentLoadingData();
        let f = dependencyMapping[e].selectOptions[l].optionName;
        if (i != 1) {
          d = roundValue(
            roundType[productConfig.loadingFactorRoundingType],
            d * i,
            productConfig.loadingFactorRoundingNum
          ), coverageOptionPrice[e].gwtPremiumWithLoading == 0 && (p = roundValue(
            roundType[productConfig.loadingFactorRoundingType],
            p * i,
            productConfig.loadingFactorRoundingNum
          ), coverageOptionPrice[e].gwtPremiumWithLoading = p);
          let P = dependencyMapping[e].flagCover ? e : dependencyMapping[e].parentCover;
          isValid(o) || (o = calculateInstallmentAmount(
            p,
            P
          ).recurringAmount);
          let m = calculateInstallmentAmount(
            d,
            P
          );
          a[l][r].prem = d, a[l][r].diff = roundValue(
            roundType.ROUND_EVEN_HALF,
            m.recurringAmount - o,
            2
          );
        }
        if (Object.keys(coverageOptionPrice[e][f]).length > 0)
          coverageOptionPrice[e][f][r] = a[l][r].diff;
        else {
          let P = {};
          P[r] = a[l][r].diff, coverageOptionPrice[e][f] = P;
        }
      }
    });
  }), coverageOptionPrice[e].flagYesOrNo = n;
}
function getDepedentCodePremiumOfCover(e, a, t) {
  let i = depCodeOptionsList[e][a], o = 0;
  if (isValid(i)) {
    let n = getDepedentCodeSelectedValue(
      e,
      a,
      t
    );
    o = o + isNumeric(i[n]) ? parseFloat(i[n]) : 0;
  }
  return o;
}
function getDepedentCodeSelectedValue(e, a, t) {
  let i = depCodeOptionsList[e][a], o = t.split("_"), n = i.optionSelectFormat, l = n.indexOf("<");
  do {
    let r = n.indexOf(">"), u = n.substring(l + 1, r);
    n = n.replace(
      "<" + u + ">",
      o[parseInt(u) - 1]
    ), l = n.indexOf("<");
  } while (l >= 0);
  return n;
}
function generateSelectionFormatForDepCode(e, a, t) {
  let i = "", o = t ? String(t).split("_") : null;
  return Object.keys(dependencyMapping[a].selectOptions).forEach(
    (n) => {
      let l = dependencyMapping[a].selectOptions[n];
      if (l.optionName.indexOf(e + ".") >= 0) {
        let r = findSelectIndexByCoverageNameAndOptName(
          e,
          l.optionName.substring(
            l.optionName.indexOf(".") + 1
          )
        );
        i = i + "_<" + r + ">";
      } else
        i = i + "_" + o[parseInt(n) - 1];
    }
  ), i.substring(1);
}
function findSelectIndexByCoverageNameAndOptName(e, a) {
  let t = null;
  return Object.keys(dependencyMapping[e].selectOptions).forEach(
    (i) => {
      dependencyMapping[e].selectOptions[i].optionName === a && (t = i);
    }
  ), t;
}
function prepareAvailableOptions() {
  for (const e of Object.keys(selectedPlan))
    dependencyMapping[e].flagOtherCodeDepOptions ? coverageOptionPrice[e].flagYesOrNo = !0 : prepareAvailableOptionsForAnItem(e), isValid(selectedPlan[e].defaultPlan) && coverageOptionPrice[e].flagYesOrNo && (selectedPlan[e].selected = !0);
}
function updateSelectedPlanOnDepCode(e, a, t, i, o) {
  populateDepCodeOptionsList(
    e,
    a,
    selectedPlan[a].defaultPlan
  );
  let n = getDepedentCodeSelectedValue(
    e,
    a,
    t
  );
  dependencyMapping[a].selectOptions[1].optionName;
  let l = depCodeOptionsList[e][a];
  isValid(l[n]) && (o && i || !o) ? (selectedPlan[a].defaultPlan = n, selectedPlan[a].selected = !0) : (selectedPlan[a].defaultPlan = null, selectedPlan[a].selected = !1);
}
function generateDefaultPlanForOptional(e, a, t) {
  let i = [];
  return dependencyMapping[e].flagSelectionDepedent && Object.keys(dependencyMapping[e].selectOptions).forEach((o) => {
    const n = dependencyMapping[e].selectOptions[o];
    if (n.flagDep) {
      const l = n.optionName.split("."), r = selectedPlan[l[0]][l[1]];
      isValid(r) && i.push(r);
    } else
      a == o && i.push(t);
  }), i;
}
function updateSelectedPlan(e, a, t) {
  let i = selectedPlan[e].defaultPlan, o = dependencyMapping[e].selectOptions[a].optionName;
  if (dependencyMapping[e].flagOtherCodeDepOptions)
    updateSelectedPlanOnDepCode(
      dependencyMapping[e].parentCover,
      e,
      selectedPlan[dependencyMapping[e].parentCover].defaultPlan,
      t,
      !0
    );
  else if (coverageOptionPrice[e].flagYesOrNo)
    if (!isValid(i) && dependencyMapping[e].flagMultipleOptionExists && t) {
      let n = generateDefaultPlanForOptional(e, 1, "1");
      selectedPlan[e].defaultPlan = n.join("_");
    } else
      selectedPlan[e].defaultPlan = t ? "1" : null, selectedPlan[e][o] = selectedPlan[e].defaultPlan;
  else if (dependencyMapping[e].flagMultipleOptionExists)
    if (t != "--None--")
      if (isValid(i)) {
        let n = i.split("_");
        n[parseInt(a) - 1] = t, selectedPlan[e].defaultPlan = n.join("_");
      } else {
        let n = generateDefaultPlanForOptional(e, a, t);
        selectedPlan[e].defaultPlan = n.join("_");
      }
    else {
      let n = i.split("_");
      n.forEach((l) => {
        n[l] = null;
      }), selectedPlan[e].defaultPlan = null;
    }
  else
    t != "--None--" ? (selectedPlan[e].defaultPlan = t, selectedPlan[e][o] = t) : (selectedPlan[e].defaultPlan = null, selectedPlan[e][o] = null);
  dependencyMapping[e].premiumDepedentCode.forEach((n) => {
    isValid(selectedPlan[n].defaultPlan) && updateSelectedPlanOnDepCode(
      e,
      n,
      selectedPlan[e].defaultPlan,
      t,
      !1
    );
  }), calculatePlanOptionsPrice(!1);
}
async function calculateSelectedPlanPrice(e) {
  isValid(initConfig) || await initProductConfig(productType), isValid(policyServiceMappingFromPricing) || await initPricingPolicyConfig(), isValid(dependencyMapping) || await initDependencyMapping(), await updateSelectedPlanForInputPricing(), isValid(policyServiceInputObj.root.quoteData.transactionType) && policyServiceInputObj.root.quoteData.transactionType != "New Biz" && policyServiceInputObj.root.quoteData.transactionType != "Renewal" ? (updatePreviousCoverForEndorPricing(), inputPricingObject.root.Quote.Policy_Payment_Frequency = paymentFrequency, await calculatePlanPriceWithPaymentOptions(
    e,
    endPoints.EndorsementPlanPrice.url,
    endPoints.EndorsementPlanPrice.interceptorUrl,
    endPoints.EndorsementPlanPrice.flagConcurrent,
    endPoints.EndorsementPlanPrice.interceptorServiceUrl
  )) : await calculatePlanPriceWithPaymentOptions(
    e,
    endPoints.GetPlanPrice.url,
    endPoints.GetPlanPrice.interceptorUrl,
    endPoints.GetPlanPrice.flagConcurrent,
    endPoints.EndorsementPlanPrice.interceptorServiceUrl
  );
}
function updatePreviousCoverForEndorPricing() {
  inputPricingObject.root.Quote.PreviousCovers = [], latestPolicyResponse.quote.quoteLineItemsData.forEach((a) => {
    let t = {};
    t.endDate = a.endDate, t.annualVAT = a.annualVAT, t.policyGrossPremium = a.policyGrossPremium, t.taxDeductionAmount = a.taxDeductionAmount, t.policyStampDuty = a.policyStampDuty, t.annualNetPremium = a.annualNetPremium, t.productId = a.productId, t.policyVAT = a.policyVAT, t.selectedOption = a.selectedOption, t.transVAT = a.transVAT, t.coverCode = a.coverCode, t.annualCommissionAmount = a.annualCommissionAmount, t.startDate = a.startDate, t.priceBookEntryId = a.priceBookEntryId, t.annualStampDuty = a.annualStampDuty, t.transNetPremium = a.transNetPremium, t.sumAssured = a.sumAssured, t.annualGrossPremium = a.annualGrossPremium, t.startTime = a.startTime, t.transStampDuty = a.transStampDuty, t.endorseEffectiveDate = a.endorseEffectiveDate, t.transGrossPremium = a.transGrossPremium, t.coverStatus = a.coverStatus, t.productCode = a.productCode, t.policyNetPremium = a.policyNetPremium, inputPricingObject.root.Quote.PreviousCovers.push(t);
  });
}
async function updateSelectedPlanForInputPricing() {
  let e = inputPricingObject.root.Quote.Covers, a = [];
  e.forEach((t) => {
    isValid(selectedPlan[t.Code]) && a.push(t.Code), isValid(t.Coverages) && t.Coverages.forEach((i) => {
      isValid(selectedPlan[i.Additional_Coverage_Code]) && a.push(i.Additional_Coverage_Code);
    });
  }), a.forEach((t) => {
    if (!isValid(selectedPlan[t]) || !isValid(selectedPlan[t].defaultPlan)) {
      let i = null;
      if (dependencyMapping[t].flagCover)
        i = e.findIndex(
          (o) => o.Code === t
        ), inputPricingObject.root.Quote.Covers.splice(i, 1);
      else if (i = e.findIndex(
        (o) => o.Code === dependencyMapping[t].parentCover
      ), i > -1) {
        let o = e[i].Coverages.findIndex(
          (n) => n.Additional_Coverage_Code === t
        );
        inputPricingObject.root.Quote.Covers[i].Coverages.splice(
          o,
          1
        );
      }
    }
  }), Object.keys(selectedPlan).forEach((t) => {
    if (isValid(selectedPlan[t].defaultPlan)) {
      let i = null;
      if (dependencyMapping[t].flagCover)
        if (i = e.findIndex((o) => o.Code === t), i < 0) {
          let o = {};
          o.Code = t, o.Selected_Option = selectedPlan[t].defaultPlan, inputPricingObject.root.Quote.Covers.push(o);
        } else
          inputPricingObject.root.Quote.Covers[i].Selected_Option = selectedPlan[t].defaultPlan;
      else {
        if (i = e.findIndex(
          (l) => l.Code === dependencyMapping[t].parentCover
        ), i < 0) {
          let l = {};
          l.Code = dependencyMapping[t].parentCover, l.Selected_Option = selectedPlan[dependencyMapping[t].parentCover].defaultPlan, l.Coverages = [], inputPricingObject.root.Quote.Covers.push(l), i = e.findIndex(
            (r) => r.Code === dependencyMapping[t].parentCover
          );
        } else
          inputPricingObject.root.Quote.Covers[i].Selected_Option = selectedPlan[dependencyMapping[t].parentCover].defaultPlan;
        isValid(inputPricingObject.root.Quote.Covers[i].Coverages) || (inputPricingObject.root.Quote.Covers[i].Coverages = []);
        let o = inputPricingObject.root.Quote.Covers[i].Coverages.findIndex(
          (l) => l.Additional_Coverage_Code === t
        ), n = selectedPlan[t].defaultPlan.split("_")[0];
        if (o < 0) {
          let l = {};
          l.Additional_Coverage_Code = t, l.Additional_Coverage_Sum_Assured = n, inputPricingObject.root.Quote.Covers[i].Coverages.push(l);
        } else
          inputPricingObject.root.Quote.Covers[i].Coverages[o].Additional_Coverage_Sum_Assured = n;
      }
    }
  });
}
function transformToPolicyServiceInput() {
  let e = planPriceWithInstResponse.root.Quote.Covers, a = [];
  Object.keys(e).forEach((t) => {
    let i = {};
    i.coverCode = t, i.productId = e[t].priceBookEntryId, i.sumAssured = e[t].Sum_Assured;
    let o = inputPricingObject.root.Quote.Covers.findIndex(
      (n) => n.Code === t
    );
    i.selectedOption = inputPricingObject.root.Quote.Covers[o].Selected_Option, i.adjustedTechnicalPremium = inputPricingObject.root.Quote.Covers[o].Prem_Details_Technical_Adjusted, i.reasonForPremiumAdjustment = inputPricingObject.root.Quote.Covers[o].Prem_Details_Reason_For_Adjustment, i.additionalCoverageName1 = isValid(
      e[t].additionalCoverageCode1
    ) ? e[t].additionalCoverageCode1 : null, i.additionalCoveragePremium1 = isValid(
      e[t].additionalCoveragePremium1
    ) ? e[t].additionalCoveragePremium1 : null, i.additionalCoverageRate1 = isValid(
      e[t].additionalCoverageRate1
    ) ? e[t].additionalCoverageRate1 : null, i.additionalCoverageSumAssured1 = isValid(
      e[t].additionalCoverageSumAssured1
    ) ? e[t].additionalCoverageSumAssured1 : null, i.additionalCoverageName2 = isValid(
      e[t].additionalCoverageCode2
    ) ? e[t].additionalCoverageCode2 : null, i.additionalCoveragePremium2 = isValid(
      e[t].additionalCoveragePremium2
    ) ? e[t].additionalCoveragePremium2 : null, i.additionalCoverageSumAssured2 = isValid(
      e[t].additionalCoverageSumAssured2
    ) ? e[t].additionalCoverageSumAssured2 : null, i.additionalCoverageRate2 = isValid(
      e[t].additionalCoverageRate2
    ) ? e[t].additionalCoverageRate2 : null, i.additionalCoverageName3 = isValid(
      e[t].additionalCoverageCode3
    ) ? e[t].additionalCoverageCode3 : null, i.additionalCoveragePremium3 = isValid(
      e[t].additionalCoveragePremium3
    ) ? e[t].additionalCoveragePremium3 : null, i.additionalCoverageSumAssured3 = isValid(
      e[t].additionalCoverageSumAssured3
    ) ? e[t].additionalCoverageSumAssured3 : null, i.additionalCoverageRate3 = isValid(
      e[t].additionalCoverageRate3
    ) ? e[t].additionalCoverageRate3 : null, i.additionalCoverageName4 = isValid(
      e[t].additionalCoverageCode4
    ) ? e[t].additionalCoverageCode4 : null, i.additionalCoveragePremium4 = isValid(
      e[t].additionalCoveragePremium4
    ) ? e[t].additionalCoveragePremium4 : null, i.additionalCoverageSumAssured4 = isValid(
      e[t].additionalCoverageSumAssured4
    ) ? e[t].additionalCoverageSumAssured4 : null, i.additionalCoverageRate4 = isValid(
      e[t].additionalCoverageRate4
    ) ? e[t].additionalCoverageRate4 : null, i.additionalCoverageName5 = isValid(
      e[t].additionalCoverageCode5
    ) ? e[t].additionalCoverageCode5 : null, i.additionalCoveragePremium5 = isValid(
      e[t].additionalCoveragePremium5
    ) ? e[t].additionalCoveragePremium5 : null, i.additionalCoverageSumAssured5 = isValid(
      e[t].additionalCoverageSumAssured5
    ) ? e[t].additionalCoverageSumAssured5 : null, i.additionalCoverageRate5 = isValid(
      e[t].additionalCoverageRate5
    ) ? e[t].additionalCoverageRate5 : null, i.additionalCoverageName6 = isValid(
      e[t].additionalCoverageCode6
    ) ? e[t].additionalCoverageCode6 : null, i.additionalCoveragePremium6 = isValid(
      e[t].additionalCoveragePremium6
    ) ? e[t].additionalCoveragePremium6 : null, i.additionalCoverageSumAssured6 = isValid(
      e[t].additionalCoverageSumAssured6
    ) ? e[t].additionalCoverageSumAssured6 : null, i.additionalCoverageRate6 = isValid(
      e[t].additionalCoverageRate6
    ) ? e[t].additionalCoverageRate6 : null, i.additionalCoverageName7 = isValid(
      e[t].additionalCoverageCode7
    ) ? e[t].additionalCoverageCode7 : null, i.additionalCoveragePremium7 = isValid(
      e[t].additionalCoveragePremium7
    ) ? e[t].additionalCoveragePremium7 : null, i.additionalCoverageSumAssured7 = isValid(
      e[t].additionalCoverageSumAssured7
    ) ? e[t].additionalCoverageSumAssured7 : null, i.additionalCoverageRate7 = isValid(
      e[t].additionalCoverageRate7
    ) ? e[t].additionalCoverageRate7 : null, i.additionalCoverageName8 = isValid(
      e[t].additionalCoverageCode8
    ) ? e[t].additionalCoverageCode8 : null, i.additionalCoveragePremium8 = isValid(
      e[t].additionalCoveragePremium8
    ) ? e[t].additionalCoveragePremium8 : null, i.additionalCoverageSumAssured8 = isValid(
      e[t].additionalCoverageSumAssured8
    ) ? e[t].additionalCoverageSumAssured8 : null, i.additionalCoverageRate8 = isValid(
      e[t].additionalCoverageRate8
    ) ? e[t].additionalCoverageRate8 : null, i.policyNetPremium = isValid(
      e[t].policyNetPremium
    ) ? e[t].policyNetPremium : null, i.policyStampDuty = isValid(
      e[t].policyStampDuty
    ) ? e[t].policyStampDuty : null, i.policyVAT = isValid(
      e[t].policyVAT
    ) ? e[t].policyVAT : null, i.policyGrossPremium = isValid(
      e[t].policyGrossPremium
    ) ? e[t].policyGrossPremium : null, i.policyCommissionAmount = isValid(
      e[t].policyCommissionAmount
    ) ? e[t].policyCommissionAmount : null, i.transNetPremium = isValid(
      e[t].transNetPremium
    ) ? e[t].transNetPremium : null, i.transStampDuty = isValid(
      e[t].transStampDuty
    ) ? e[t].transStampDuty : null, i.transVAT = isValid(
      e[t].transVAT
    ) ? e[t].transVAT : null, i.transGrossPremium = isValid(
      e[t].transGrossPremium
    ) ? e[t].transGrossPremium : null, i.transCommissionAmount = isValid(
      e[t].transCommissionAmount
    ) ? e[t].transCommissionAmount : null, i.annualNetPremium = isValid(
      e[t].annualNetPremium
    ) ? e[t].annualNetPremium : null, i.annualStampDuty = isValid(
      e[t].annualStampDuty
    ) ? e[t].annualStampDuty : null, i.annualVAT = isValid(
      e[t].annualVAT
    ) ? e[t].annualVAT : null, i.annualGrossPremium = isValid(
      e[t].annualGrossPremium
    ) ? e[t].annualGrossPremium : null, i.annualCommissionAmount = isValid(
      e[t].annualCommissionAmount
    ) ? e[t].annualCommissionAmount : null, i.otherDiscount = isValid(
      e[t].Other_Discount
    ) ? e[t].Other_Discount : null, a.push(i);
  }), policyServiceInputObj.root.quoteData.quoteLineItemsData = a, policyServiceInputObj.root.quoteData.priceBookEntryId = planPriceWithInstResponse.root.Quote.productId, policyServiceInputObj.root.quoteData.productVersionNo = planPriceWithInstResponse.root.Quote.Policy_Product_Version, policyServiceInputObj.root.quoteData.paymentOptions = paymentOptions, mapPolicyServiceFromPricing(planPriceWithInstResponse);
}
function mapPolicyServiceFromPricing(e) {
  if (isValid(policyServiceMappingFromPricing)) {
    let a = policyServiceMappingFromPricing.pricingPolicyConfig;
    Object.keys(a).forEach((t) => {
      var n, l;
      let i = e.root, o = policyServiceInputObj.root;
      if (isValid(a[t])) {
        let r = a[t].ObjectPath.policyServiceObjectPath, u = a[t].ObjectPath.inputPriceResponseObjectPath, s = (n = a[t].ObjectPath.fieldToCompare) == null ? void 0 : n.policyServiceObject, c = (l = a[t].ObjectPath.fieldToCompare) == null ? void 0 : l.inputPriceResponseObject, d = a[t].ObjectPath.isArray, p = r.split(".");
        u.split(".").forEach((P) => {
          i = i[P];
        }), p.forEach((P) => {
          o = o[P];
        }), d ? Object.keys(i).forEach((P) => {
          o.forEach((m) => {
            i[P][c] == m[s] && mapFields(
              i[P],
              m,
              a[t].fieldsToMap
            );
          });
        }) : mapFields(
          i,
          o,
          a[t].fieldsToMap
        );
      }
    });
  }
}
function mapFields(e, a, t) {
  Object.entries(t).forEach(([i, o]) => {
    a[i] = e[o] != null ? new String(e[o]) : null;
  });
}
async function saveQuote(e) {
  if (policyServiceInputObj.root.quoteData.isActivatePolicy || policyServiceInputObj.root.quoteData.paymentData.flagPaymentConfirmed || policyServiceInputObj.root.quoteData.paymentData.flagDeferredPayment ? await calculatePlanPriceWithInstallments(e, !0) : (await calculateSelectedPlanPrice(e), policyServiceInputObj.root.quoteData.paymentData.paymentDetailsData = []), transformToPolicyServiceInput(), validateToCallInterceptorURL(
    endPoints.CreatePolicy.interceptorUrl,
    e
  )) {
    let a = makeInterceptorRequestObj(
      policyServiceInputObj,
      endPoints.CreatePolicy.interceptorServiceUrl
    );
    return await apiService.post(
      endPoints.CreatePolicy.interceptorUrl,
      a,
      e.metadata.headers,
      e.metadata.requestParams,
      endPoints.CreatePolicy.flagConcurrent
    );
  } else
    return await apiService.post(
      endPoints.CreatePolicy.url,
      policyServiceInputObj,
      headerUUID,
      null,
      endPoints.CreatePolicy.flagConcurrent
    );
}
function createHeaderUUID(e) {
  let a = {};
  a.key = "X-UUID", a.value = e, headerUUID.push(a);
}
async function retrievePolicy(e, a) {
  var o, n;
  let t = null;
  if (validateToCallInterceptorURL(
    endPoints.RetrievePolicy.interceptorUrl,
    a
  )) {
    let l = makeInterceptorRequestObj(
      e,
      endPoints.RetrievePolicy.interceptorServiceUrl
    );
    t = await apiService.post(
      endPoints.RetrievePolicy.interceptorUrl,
      l,
      a.metadata.headers,
      a.metadata.requestParams,
      endPoints.CreatePolicy.flagConcurrent
    );
  } else
    t = await apiService.post(
      endPoints.RetrievePolicy.url,
      e,
      headerUUID,
      null,
      endPoints.CreatePolicy.flagConcurrent
    );
  const i = t.quote.mastersetId;
  return productType = t.quote.productType, t.quote.hasOwnProperty("productVersionNo") && isValid(t.quote.oductVersionNo) && (productVersionNo = t.quote.productVersionNo), latestTransactedQuoteId = t.quote.latestTransactedQuoteId, updateMasterSetIdAndProductVersion(
    i,
    t.quote.productVersionNo
  ), isValid(t.quote.transactionType) && t.quote.transactionType != "New Biz" && t.quote.transactionType != "Renewal" && retrieveLatestPolicy(a), isValid(paymentFrequency) || (paymentFrequency = (n = (o = t.quote) == null ? void 0 : o.paymentData) == null ? void 0 : n.paymentFrequency), await updateSelectedPlanAfterRetrievePolicy(t), t;
}
function updateMasterSetIdAndProductVersion(e, a) {
  isValid(policyServiceInputObj) && (policyServiceInputObj.root.quoteData.mastersetId = e), isValid(inputPricingObject) && (inputPricingObject.root.Quote.mastersetId = e), isValid(policyProductVersionNo) || (policyProductVersionNo = a);
}
async function retrieveLatestPolicy(e) {
  let a = null;
  quoteId = latestTransactedQuoteId;
  let t = makeRetrievePolicyObj();
  if (validateToCallInterceptorURL(
    endPoints.RetrievePolicy.interceptorUrl,
    e
  )) {
    let i = makeInterceptorRequestObj(
      t,
      endPoints.RetrievePolicy.interceptorServiceUrl
    );
    a = await apiService.post(
      endPoints.RetrievePolicy.interceptorUrl,
      i,
      e.metadata.headers,
      e.metadata.requestParams,
      endPoints.CreatePolicy.flagConcurrent
    );
  } else
    a = await apiService.post(
      endPoints.RetrievePolicy.url,
      t,
      headerUUID,
      null,
      endPoints.CreatePolicy.flagConcurrent
    );
  latestPolicyResponse = a, updateMasterSetIdAndProductVersion(
    a.quote.mastersetId,
    a.quote.productVersionNo
  );
}
function calculateEndorsementPrice() {
  if (isValid(latestPolicyResponse)) {
    let e = latestPolicyResponse.quote.quoteLineItemsData, a = 0;
    e.forEach((t) => {
      let i = coveragePrice[t.coverCode];
      if (isValid(i)) {
        let o = coveragePrice[t.coverCode].gwtPremium - t.annualNetPremium, n = calculateGrossPremium(
          o,
          t.coverCode
        );
        coveragePrice[t.coverCode].additionlEndtPremium = n, a = a + n;
      }
    }), coveragePrice.Policy.additionlEndtPremium = a;
  }
}
async function updateSelectedPlanAfterRetrievePolicy(e) {
  e.quote.quoteLineItemsData.forEach((t) => {
    isValid(t.coverCode) && (checkSelectedPlan(t.coverCode), selectedPlan[t.coverCode].defaultPlan = t.selectedOption), isValid(t.additionalCoverageName1) && (checkSelectedPlan(t.additionalCoverageName1), selectedPlan[t.additionalCoverageName1].defaultPlan = String(
      t.additionalCoverageSumAssured1
    )), isValid(t.additionalCoverageName2) && (checkSelectedPlan(t.additionalCoverageName2), selectedPlan[t.additionalCoverageName2].defaultPlan = String(
      t.additionalCoverageSumAssured2
    )), isValid(t.additionalCoverageName3) && (checkSelectedPlan(t.additionalCoverageName3), selectedPlan[t.additionalCoverageName3].defaultPlan = String(
      t.additionalCoverageSumAssured3
    )), isValid(t.additionalCoverageName4) && (checkSelectedPlan(t.additionalCoverageName4), selectedPlan[t.additionalCoverageName4].defaultPlan = String(
      t.additionalCoverageSumAssured4
    )), isValid(t.additionalCoverageName5) && (checkSelectedPlan(t.additionalCoverageName5), selectedPlan[t.additionalCoverageName5].defaultPlan = String(
      t.additionalCoverageSumAssured5
    )), isValid(t.additionalCoverageName6) && (checkSelectedPlan(t.additionalCoverageName6), selectedPlan[t.additionalCoverageName6].defaultPlan = String(
      t.additionalCoverageSumAssured6
    )), isValid(t.additionalCoverageName7) && (checkSelectedPlan(t.additionalCoverageName7), selectedPlan[t.additionalCoverageName7].defaultPlan = String(
      t.additionalCoverageSumAssured7
    )), isValid(t.additionalCoverageName8) && (checkSelectedPlan(t.additionalCoverageName8), selectedPlan[t.additionalCoverageName8].defaultPlan = String(
      t.additionalCoverageSumAssured8
    ));
  });
}
function checkSelectedPlan(e) {
  isValid(selectedPlan[e]) || (selectedPlan[e] = {});
}
async function calculateSelectedPlanPriceWithInstallments(e, a) {
  isValid(initConfig) || await initProductConfig(productType), isValid(policyServiceMappingFromPricing) || await initPricingPolicyConfig(), isValid(dependencyMapping) || await initDependencyMapping(), await updateSelectedPlanForInputPricing(), await calculatePlanPriceWithInstallments(
    e,
    a
  );
}
async function calculatePlanPriceWithInstallments(e, a) {
  var n, l, r, u;
  inputPricingObject.root.Quote.Policy_Payment_Frequency = paymentFrequency;
  let t = null;
  isValid(policyServiceInputObj.root.quoteData.transactionType) && policyServiceInputObj.root.quoteData.transactionType != "New Biz" && policyServiceInputObj.root.quoteData.transactionType != "Renewal" ? (updatePreviousCoverForEndorPricing(), inputPricingObject.root.Quote.PaymentDetails = latestPolicyResponse.quote.paymentData.paymentDetailsData, t = await callPlanPriceWithInstallments(
    e,
    endPoints.EndorsementInst.interceptorUrl,
    endPoints.EndorsementInst.url,
    endPoints.EndorsementInst.flagConcurrent,
    endPoints.EndorsementInst.interceptorServiceUrl
  )) : t = await callPlanPriceWithInstallments(
    e,
    endPoints.GetPlanPriceInst.interceptorUrl,
    endPoints.GetPlanPriceInst.url,
    endPoints.GetPlanPriceInst.flagConcurrent,
    endPoints.EndorsementInst.interceptorServiceUrl
  ), createPlanPricePaymentFrequency(t);
  let i = null;
  isValid(latestPolicyResponse) && (i = latestPolicyResponse.quote.paymentData.paymentDetailsData);
  let o = 0;
  if (isValid(t)) {
    let s = t.root.Quote.paymentDetails, c = (l = (n = policyServiceInputObj == null ? void 0 : policyServiceInputObj.root.quoteData) == null ? void 0 : n.paymentData) == null ? void 0 : l.paymentDetailsData;
    const d = (u = (r = policyServiceInputObj == null ? void 0 : policyServiceInputObj.root.quoteData) == null ? void 0 : r.paymentData) == null ? void 0 : u.paidAmount;
    !isValid(policyServiceInputObj) && !isValid(s) && (i = []);
    let p = [];
    s.forEach((f) => {
      const P = new Date(f.dueDate), m = new Date();
      P <= m && f.status == "Pending" && (o = o + Number(f.amount), p.push(f.installmentNumber));
    }), a ? (policyServiceInputObj.root.quoteData.paymentData.paymentDetailsData = s, i = policyServiceInputObj == null ? void 0 : policyServiceInputObj.root.quoteData.paymentData.paymentDetailsData, (d == o || o <= 0) && p.forEach((f) => {
      let P = i.find(function(m) {
        if (m.installmentNumber == f)
          return m;
      });
      isValid(P) && P.status == "Pending" && policyServiceInputObj.root.quoteData.paymentData.flagPaymentConfirmed && (o > 0 ? (P.status = "Paid", isValid(c) && (P.chargeId = c[0].chargeId, P.bankName = c[0].bankName, P.bankReferenceNumber = c[0].bankReferenceNumber), P.paymentDate = new Date().toJSON().slice(0, 10)) : P.status = "Pending");
    }), paymentDetails = i) : paymentDetails = s;
  }
  isValid(policyServiceInputObj.root.quoteData.transactionType) && policyServiceInputObj.root.quoteData.transactionType != "New Biz" && policyServiceInputObj.root.quoteData.transactionType != "Renewal" && (policyPaymentOptionPrice[inputPricingObject.root.Quote.Policy_Payment_Frequency].additionalEndtInstAmount = o);
}
async function callPlanPriceWithInstallments(e, a, t, i, o) {
  let n = null;
  if (validateToCallInterceptorURL(a, e)) {
    let l = makeInterceptorRequestObj(inputPricingObject, o);
    n = await apiService.post(
      a,
      l,
      e.metadata.headers,
      e.metadata.requestParams,
      i
    );
  } else
    n = await apiService.post(
      t,
      inputPricingObject,
      headerUUID,
      null,
      i
    );
  return n;
}
async function updatePaymentDecision(e) {
  inputPricingObject.root.Quote.Payment_Decision = e, await calculatePlanPriceWithInstallments(null, !1);
}
function validateToCallInterceptorURL(e, a) {
  return !!(isValid(e) && isValid(a));
}
function makeInterceptorRequestObj(e, a) {
  let t = {}, i = {};
  i.key = "X-UUID", i.value = clientRegData.UUID, t.headers = [], t.headers.push(i);
  let o = a.split("?");
  t.uri = o[0];
  let n = o[1].split("&");
  t.requestParams = [], n.forEach((r) => {
    let u = r.split("="), s = {};
    s.key = u[0], s.value = u[1], t.requestParams.push(s);
  });
  let l = {};
  return l = e, l.metadata = t, l;
}
function makeRetrievePolicyObj() {
  return {
    data: {
      opportunityNumber: "",
      quoteId
    }
  };
}
export {
  calculateEndorsementPrice,
  calculatePlanOptionsPrice,
  calculatePlanPriceWithPaymentOptions,
  calculateSelectedPlanPrice,
  calculateSelectedPlanPriceWithInstallments,
  endPoints,
  getAvailablePaymentFrequency,
  getCoverageOptionPrice,
  getCoveragePrice,
  getDefaultPaymentFrequency,
  getDependencyCodeMapping,
  getDependencyMapping,
  getInputPricing,
  getPaymentDetailsData,
  getPlanPriceWithInstResponse,
  getPolicyPaymentOptionPrice,
  getPolicyServiceInputObj,
  getRewardPoint,
  getSelectedPlan,
  retrievePolicy,
  saveQuote,
  setClientRegInfo,
  setInputPricing,
  setLatestPolicyResponse,
  setPolicyServiceInputObj,
  updatePaymentDecision,
  updatePaymentFrequency,
  updateSelectedPlan
};